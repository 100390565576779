var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mt-4 bg-white px-8" }, [
    _c(
      "section",
      { staticClass: "vx-row" },
      [
        !_vm.isEditVersion && _vm.isAdminUser
          ? _c(
              "div",
              { staticClass: "vx-col w-full" },
              [
                _c("LabelForm", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("apps.configurator.new-version.version-number")
                    )
                  )
                ]),
                _c("vs-input", {
                  staticClass: "w-50 border-grey-light",
                  attrs: { type: "number", min: "0" },
                  model: {
                    value: _vm.versionMajor,
                    callback: function($$v) {
                      _vm.versionMajor = $$v
                    },
                    expression: "versionMajor"
                  }
                })
              ],
              1
            )
          : _vm._e(),
        !_vm.isEditVersion && _vm.isAdminUser
          ? _c(
              "div",
              { staticClass: "vx-col w-full" },
              [
                _c("LabelForm", [
                  _vm._v(_vm._s(_vm.$t("apps.configurator.new-version.from")))
                ]),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.cloneVersionUuid,
                        expression: "cloneVersionUuid"
                      }
                    ],
                    staticClass: "w-50 border-grey-light input-modal",
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.cloneVersionUuid = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      }
                    }
                  },
                  [
                    _c("option", { domProps: { value: null } }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "apps.configurator.new-version.create_empty_version"
                          )
                        )
                      )
                    ]),
                    _vm._l(_vm.versions, function(version) {
                      return _c(
                        "option",
                        {
                          key: version.uuid,
                          domProps: { value: version.uuid }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(version.version_major) +
                              " - " +
                              _vm._s(version.description) +
                              " - " +
                              _vm._s(_vm.formatDate(version.updated_at)) +
                              " "
                          )
                        ]
                      )
                    })
                  ],
                  2
                )
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "vx-col w-full" },
          [
            _c("LabelForm", [
              _vm._v(
                _vm._s(_vm.$t("apps.configurator.new-version.description"))
              )
            ]),
            _c("vs-input", {
              staticClass: "w-50 border-grey-light",
              attrs: { type: "text", maxlength: "100" },
              model: {
                value: _vm.description,
                callback: function($$v) {
                  _vm.description = $$v
                },
                expression: "description"
              }
            })
          ],
          1
        ),
        _vm.isAdminUser
          ? _c(
              "div",
              { staticClass: "vx-col w-full" },
              [
                _c("LabelForm", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("apps.configurator.new-version.enable-menu-tab")
                    )
                  )
                ]),
                _c(
                  "vs-select",
                  {
                    staticClass: "w-25 border-grey-light",
                    model: {
                      value: _vm.enableMenuTabValue,
                      callback: function($$v) {
                        _vm.enableMenuTabValue = $$v
                      },
                      expression: "enableMenuTabValue"
                    }
                  },
                  _vm._l(_vm.enableMenuTabOps, function(enableMenuTab) {
                    return _c("vs-select-item", {
                      key: enableMenuTab.value,
                      attrs: {
                        value: enableMenuTab.value,
                        text: enableMenuTab.label
                      }
                    })
                  }),
                  1
                )
              ],
              1
            )
          : _vm._e(),
        _c(
          "b-alert",
          {
            staticClass: "my-5 rounded-lg vx-col w-full",
            attrs: { show: _vm.errorsOnSave.length > 0, variant: "danger" }
          },
          [
            _c(
              "ul",
              { staticClass: "list-none" },
              _vm._l(_vm.errorsOnSave, function(errorString, index) {
                return _c("li", { key: index }, [_vm._v(_vm._s(errorString))])
              }),
              0
            )
          ]
        ),
        _c(
          "div",
          { staticClass: "my-10 vx-col w-full" },
          [
            _c(
              "vs-button",
              {
                attrs: { color: "primary", type: "border rounded-lg mr-3" },
                on: {
                  click: function($event) {
                    return _vm.cancel()
                  }
                }
              },
              [_vm._v(_vm._s(_vm.$t("apps.configurator.new-version.cancel")))]
            ),
            _c(
              "vs-button",
              {
                attrs: { color: "primary", type: "filled rounded-lg" },
                on: {
                  click: function($event) {
                    return _vm.saveVersion()
                  }
                }
              },
              [_vm._v(_vm._s(_vm.$t("apps.configurator.new-version.save")))]
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }