<template>
    <div>
      <VersionForm/>
    </div>
</template>

<script>

import VersionForm from '@/modules/Apps/Components/version/VersionForm'

export default {
    name: 'manage-version',
    components: {
      VersionForm
    }
}
</script>
