<template>
    <div class="mt-4 bg-white px-8">
        <section class="vx-row">
            <div class="vx-col w-full" v-if="!isEditVersion && isAdminUser">
                <LabelForm>{{ $t('apps.configurator.new-version.version-number') }}</LabelForm>
                <vs-input type="number" min="0" class="w-50 border-grey-light" v-model="versionMajor" />
            </div>
            <div class="vx-col w-full" v-if="!isEditVersion && isAdminUser">
                <LabelForm>{{ $t('apps.configurator.new-version.from') }}</LabelForm>
                <select class="w-50 border-grey-light input-modal" v-model="cloneVersionUuid">
                    <option :value="null">{{ $t('apps.configurator.new-version.create_empty_version') }}</option>
                    <option :key="version.uuid" :value="version.uuid" v-for="version in versions">
                        {{ version.version_major }} - {{ version.description }} - {{ formatDate(version.updated_at) }}
                    </option>
                </select>
            </div>
            <div class="vx-col w-full">
                <LabelForm>{{ $t('apps.configurator.new-version.description') }}</LabelForm>
                <vs-input type="text" maxlength="100" class="w-50 border-grey-light" v-model="description" />
            </div>
            <div class="vx-col w-full" v-if="isAdminUser">
                <LabelForm>{{ $t('apps.configurator.new-version.enable-menu-tab') }}</LabelForm>
                <vs-select class="w-25 border-grey-light" v-model="enableMenuTabValue">
                    <vs-select-item :key="enableMenuTab.value" :value="enableMenuTab.value" :text="enableMenuTab.label"
                        v-for="enableMenuTab in enableMenuTabOps" />
                </vs-select>
            </div>
            <b-alert :show="errorsOnSave.length > 0" variant="danger" class="my-5 rounded-lg vx-col w-full">
                <ul class="list-none">
                    <li v-for="(errorString, index) in errorsOnSave" :key="index">{{ errorString }}</li>
                </ul>
            </b-alert>
            <div class="my-10 vx-col w-full">
                <vs-button color="primary" type="border rounded-lg mr-3" @click="cancel()">{{
                    $t("apps.configurator.new-version.cancel") }}</vs-button>
                <vs-button color="primary" type="filled rounded-lg" @click="saveVersion()">{{
                    $t("apps.configurator.new-version.save") }}</vs-button>
            </div>
        </section>
    </div>
</template>

<script>
import LabelForm from '@/modules/Shared/Components/form/LabelForm'
import ConfiguratorVersionService from '@/modules/Apps/Services/Configurator/ConfiguratorVersionService'
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

export default {
    name: 'version-form',
    components: {
        LabelForm
    },
    data: () => ({

        allStatus: [],
        enableMenuTabOps: [],
        errorsOnSave: [],
        versionUuid: uuidv4(),
        enableMenuTabValue: true,
        status: 'draft',
        description: '',
        versionMajor: '',
        isEditVersion: false,
        cloneVersionUuid: null,
        versions: [],
    }),
    computed: {
        isAdminUser() {
            return this.$store.state.auth.drm['admin'].length > 0
        },
        isCloneVersion() {
            return this.cloneVersionUuid !== null
        },
    },
    async created() {
        this.versions = (await ConfiguratorVersionService.getVersionList({ q: `application_uuid:${this.$route.params.uuid}` })).data

        this.allStatus = [
            { label: this.$t('apps.configurator.new-version.status-draft'), value: 'draft' },
            { label: this.$t('apps.configurator.new-version.status-published'), value: 'published' },
            { label: this.$t('apps.configurator.new-version.status-obsolete'), value: 'obsolete' },
        ];

        this.enableMenuTabOps = [
            { label: this.$i18n.t('yes'), value: true },
            { label: this.$i18n.t('no'), value: false },
        ]

        if(this.$route.params.versionUuid) {
           this.editVersion(this.$route.params.versionUuid)
        }
    },
    methods: {
        formatDate(timestamp) {
            return moment.unix(timestamp).format('DD/MM/YYYY HH:mm:ss')
        },
        cancel() {
            this.$router.push({ name: 'Configurator', params: { "uuid": this.$route.params.uuid } });
        },
        async editVersion(versionUuid) {
            let response = await ConfiguratorVersionService.getVersion(versionUuid)

            this.versionUuid = versionUuid,
            this.enableMenuTabValue = response.enable_menu_tab,
            this.description = response.description,
            this.versionMajor = response.version_major
            this.isEditVersion = true
        },
        async saveVersion() {
            let response
            if (!this.hasErrors()) {
                let parameters = {
                    application_uuid: this.$route.params.uuid,
                    description: this.description,
                    enable_menu_tab: this.enableMenuTabValue
                }
                if(this.isEditVersion) {
                    response = await ConfiguratorVersionService.editVersion(this.versionUuid, parameters)
                } else if(this.isCloneVersion) {
                    if (this.isCloneVersion) {
                        parameters = { version_major: this.versionMajor }
                    }
                    response = await ConfiguratorVersionService.cloneVersion(this.cloneVersionUuid, this.versionUuid, parameters)
                } else {
                    parameters = { ...parameters, version_major: this.versionMajor, status: 'draft', }
                    response = await ConfiguratorVersionService.createVersion(this.versionUuid, parameters)
                }

                if (response.code == 200) {
                    this.$vs.notify({ text: this.$t("apps.configurator.new-version.save-ok"), color: 'success', position: 'top-right' })
                    setTimeout(() => {
                        this.$router.push({ name: 'Configurator', params: { "uuid": this.$route.params.uuid } });
                    }, 1000)
                } else {
                    this.$vs.notify({ text: this.$t("apps.configurator.new-version.save-ko"), color: 'danger', position: 'top-right' })
                }
            }
        },
        hasErrors() {
            this.errorsOnSave = []

            if (!this.versionMajor) {
                this.errorsOnSave = [...this.errorsOnSave, this.$t('apps.configurator.new-version.version-major-required')]
            }

            if (!this.description) {
                this.errorsOnSave = [...this.errorsOnSave, this.$t('apps.configurator.new-version.description-required')]
            }

            if (!this.status) {
                this.errorsOnSave = [...this.errorsOnSave, this.$t('apps.configurator.new-version.status-required')]
            }

            return this.errorsOnSave.length !== 0
        },
    }
}
</script>